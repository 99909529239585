<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2">
            <i class="text-primary ti ti-user-check small-gap-right" />
            <h4 class="text-primary mb-0">
              Daftar Sertifikat
            </h4>
          </div>

          <!-- Table of staff-->
          <div>
            <!-- table -->
            <vue-good-table
              mode="remote"
              :is-loading.sync="isLoading"
              :total-rows="totalRecords"
              :columns="columns"
              :rows="rows"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'name', type: 'asc' },
              }"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                nextLabel: 'selanjutnya',
                prevLabel: 'sebelumnya',
                rowsPerPageLabel: 'Data per halaman',
                ofLabel: 'dari',
                pageLabel: 'halaman', // for 'pages' mode
                allLabel: 'Semua',
              }"
              :line-numbers="true"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Action -->
                <span
                  v-if="props.column.field === 'action'"
                  class="d-flex justify-content-start"
                >
                  <a
                    size="sm"
                    class="mr-1 btn btn-primary btn-sm"
                    target="__blank"
                    :href="`${baseUrl}/training-certificates/${props.row.id}`"
                  >
                    <i class="ti ti-zoom-in" /> Lihat
                  </a>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'

import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormRadio,
  BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { pascalCase, avatarText } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'
import { reqGetCertificates, reqGetCertificate } from '@/api/user/certificate'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormRadio,
    BFormCheckbox,

    VueGoodTable,
    vSelect,
  },
  filters: {
    statusClass(status) {
      if (status == 'Aktif') {
        return 'success'
      } if (status == 'Tidak Aktif') {
        return 'secondary'
      }
      return 'danger'
    },
  },
  props: {},
  data() {
    return {
      // Filter
      isLoading: true,
      statuses: ['Aktif', 'Tidak Aktif', 'Diblokir'],

      // Table option
      data: {},
      serverParams: {
        columnFilters: {},
        sort: {
          field: '',
          type: '',
        },

        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: 'Nama Pelatihan',
          field: 'name',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Cari nama', // placeholder for filter input
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, // custom filter function that
            trigger: 'enter', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Aksi',
          field: 'action',
          width: '120px',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    rows() {
      if (this.data.data) {
        return this.data.data
      }
      return []
    },
    totalRecords() {
      if (this.data.data) {
        return this.data.total
      }
      return 0
    },
    baseUrl(){
      return process.env.VUE_APP_BASE_API_URL+'/api'
    }
  },
  created() {
    this.loadItems()
  },
  methods: {
    loadItems() {
      reqGetCertificates(this.serverParams).then(response => {
        this.data = response.data.data
        this.isLoading = false
      })
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    handleShow(row) {
      this.$router.go(`${process.env.VUE_APP_BASE_API_URL}/training-certificates/${$route.params.certificateId}`)
    },
    handleDelete(row) {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        text: 'Anda tidak akan bisa mengembalikan data ini lagi!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.isConfirmed) {
          reqDeleteStaff(row.id)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil',
                  icon: 'Check',
                  message: response.data.message || 'Berhasil menghapus data',
                  variant: 'success',
                },
              })
              this.loadItems()
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Gagal',
                  icon: 'Check',
                  message:
                    error.response.data.message || 'Gagal menghapus data',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    triggerModal() {
      this.$bvModal.show('content-add-modal')
    },
    avatarText,
    pascalCase,
    checkAuthorizeRole,
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-good-table.scss";
.small-gap-right {
  margin-right: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.line-numbers {
  padding: 0 1.2em !important;
}

.school-filter {
  min-width: 180px !important;
}
</style>
